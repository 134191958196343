<template>
  <div class="demo2">
    <div class="demo_left">
      <div id="rootdemo2"></div>
    </div>
    <div class="demo_right">
      <div class="righttop">
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="Speaktext"
          >{{ answer }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { generate, getDigitalMan, queAnswers } from "@/api";
import VoiceToText from "./VoiceToText";
export default {
  mixins: [VoiceToText],
  data() {
    return {
      answer: "开始说话",
      text: "",
      player: null,
      keyFlag: false,
      plugin: window["@shuwen/rtc-plugin"],
      iptFocus: false,
      accessToken: "",
    };
  },
  watch: {
    resultText(val) {
      if (val) {
        this.text = val;
        this.player.sendSpeak(this.text);
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    keydown() {
      document.onkeydown = (event) => {
        if (this.iptFocus) {
          return;
        }
        if (event.keyCode === 32) {
          if (!this.keyFlag) {
            this.keyFlag = true;
          }
        }
      };
      document.onkeyup = (event) => {
        if (this.iptFocus) {
          return;
        }
        if (event.keyCode === 32) {
          this.keyFlag = false;
        }
      };
    },
    init() {
      this.plugin
        .getToken({
          ak: "18b55377ee2c4f3daf59a389bd9c527d",
          username: "root",
          password: "heheheh",
          endpoint: location.host, // 域名
        })
        .then((res) => {
          if (res && res.data && res.success) {
            this.accessToken = res.data.vhostToken;
            this.player = new this.plugin.RtcPlayer({
              // pos: ["0", "0"], // 位置： x y
              // size: ["500px", "500px"], // 大小： 宽 高
              streamId: "91ca42dfdf5111ed8d775db71f785691",
              dom: document.getElementById("rootdemo2"),
              accessToken: this.accessToken, // 可通过 getToken API获取后传入，也可直接传入
              streamType: "manyOfOne", //manyOfOne：共用一路
              endpoint: "vhost-entry.shuwen.com", // 域名，测试/正式 可能不同
            });
            this.player.on("load", function () {
              console.log("加载完成");
            });
            // error事件，用来监听一些错误
            this.player.on("error", function (error) {
              console.log("error", error);
            });
            // speakStart事件，用来监听主播开始说话
            this.player.on("speakStart", function () {
              // 开始说话后的一些操作，比如显示主播
              console.log("speakStart");
            });
            // speakEnd事件，用来监听主播说话结束
            this.player.on("speakEnd", function () {
              // 说话结束后的一些操作，比如隐藏主播
              console.log("speakEnd");
            });
          } else {
            console.error("登陆失败");
          }
        });
    },
    Speaktext() {
      if (this.answer === "开始说话") {
        this.start();
        this.answer = "停止说话";
      } else {
        this.end();
        this.answer = "开始说话";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.demo2 {
  width: 100vw;
  height: 100vh;
  display: flex;
  min-width: 1200px;
  .demo_left {
    width: 500px;
    #rootdemo2 {
      width: 500px;
      height: 500px;
      position: relative;
      ::v-deep canvas {
        width: 100% !important;
        left: 15% !important;
      }
    }
    .videobox {
      width: 500px;
      height: 500px;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
  .demo_right {
    flex: 1;
    overflow: auto;
    .righttop {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      .ipt {
        height: 100%;
      }
      .el-button {
        height: 100%;
      }
    }
  }
}
</style>